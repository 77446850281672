import React from 'react';

const Home = () => {
  const services = [
    { img: 'android.svg', title: 'Android Development', text: 'Empower your business with swift and intuitive mobile applications crafted to perfection.' },
    { img: 'apple.svg', title: 'iOS Development', text: 'Unlock the potential of your business with fast and intuitive mobile applications designed for iOS devices.' },
    { img: 'ui.svg', title: 'UI UX Design', text: 'Experience the art of crafting a unique user interface and delivering a seamless user experience.' },
    { img: 'web.svg', title: 'Web Development', text: 'Harness the potential of fast and user-friendly web applications to propel your business forward.' },
    { img: 'qa.svg', title: 'QA Solutions', text: 'We ensure flawless online platform launches through rigorous testing in our Quality Assurance Solutions.' },
    { img: 'cms.svg', title: 'CMS Development', text: 'Crafting tailored digital solutions for your needs, our CMS development expertise shapes your digital future.' },
  ];

  const approachSteps = [
    { img: 'listening.svg', title: 'Listening', text: "We excel in active listening, allowing us to truly understand our clients' product requirements and empathize with their vision." },
    { img: 'planning.svg', title: 'Planning', text: 'Strategic planning ensures preparedness for product development challenges, delivering top-notch solutions.' },
    { img: 'design.svg', title: 'Design', text: 'Our talented design team will create wireframes and deliver comprehensive design assets, turning your product concept into a visually stunning reality.' },
    { img: 'devlopment.svg', title: 'Development', text: 'Our collaborative team of developers will deliver meticulously structured, reusable, and robust code to power your product.' },
    { img: 'testing.svg', title: 'Testing', text: 'Our dedicated testing experts will conduct comprehensive testing to ensure your product\'s flawless functionality, including scripted, automated, and user acceptance testing.' },
    { img: 'launch.svg', title: 'Launch', text: 'We treat product launches with the same enthusiasm as our initial development, celebrating each product\'s debut with zeal and precision.' },
    { img: 'support.svg', title: 'Support', text: 'We take pride in offering support and welcome any helping hands to ensure your product runs smoothly and continues to meet your customers\' needs.' },
    { img: 'happy_client.svg', title: 'Delighted Clients', text: 'Bringing a smile to our clients\' faces is the ultimate reward, earning us the extra brownie points we cherish in the product-based industry.' },
  ];

  const techStacks = [
    { category: 'Mobile App', items: ['android.svg', 'java.svg', 'kotlin.svg', 'swift.svg', 'flutter.svg', 'react-native.svg', 'xamarin-svgrepo-com.svg'] },
    { category: 'Front-end', items: ['html.svg', 'css.svg','javascript.svg', 'bootstrap-4.svg',  'react-js.svg','angular.svg',  'vue.svg', 'tailwind-svgrepo-com.svg'] },
    { category: 'Back-end', items: ['php-svgrepo-com.svg', 'laravel-svgrepo-com.svg', 'codeigniter.svg', 'nodejs-svgrepo-com.svg', 'python.svg', 'ruby-svgrepo-com.svg'] },
    { category: 'Quality Analyst', items: ['jira.svg', 'trello.svg', 'figma.svg', 'adobexd.svg', 'adobe.svg', 'ps.svg'] },   
  ];

  return (
    <>
      {/* ======= home component start =============== */}
      <section id="home" className="section">
        {/*------- hero section start ----------- */}
        <div className="hero">
          <div className="hero_animation">
            {[...Array(40)].map((_, index) => (
              <div key={index} className="line" />
            ))}
          </div>
          <div className="hero_main">
            <div className="container">
              <div className="slider_text z_index">
                <div className="row">
                  <div className="col-lg-6 col-md-6 m-auto">
                    <div className="content_sec">
                      <p className="revealer text-white mb-4">"WE ARE Lalit Mittal Technologies Pvt Ltd."</p>
                      <div className="comingsoon-loading">
                        <h1 className="revealer" data-text="Driving digital success">
                          Driving digital success
                        </h1>
                      </div>
                      <h1 className="revealer">
                        <br /> with quality at the core .{' '}
                      </h1>
                      <p className="revealer mb-4 text-white col-12 col-sm-10 col-md-10 col-lg-12">
                        As a worldwide specialist in digital transformation, we enable enterprises & start-ups to
                        achieve enduring business outcomes through their digital investments.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="img_sec">
                      <img
                        src="assets/img/home/banner/home_banner.svg"
                        className="revealer img-fluid"
                        loading="lazy"
                        alt="Home Banner"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="social_hero">
            <div className="container-fluid comn_spcing">
              <ul className="social_icon">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=61557189986966" target="_blank" rel="noopener noreferrer">
                    <img src="assets/img/footer/facebook.svg" alt="Facebook" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/lalit-mittal-9155712bb/" target="_blank" rel="noopener noreferrer">
                    <img src="assets/img/footer/linkedin.svg" alt="LinkedIn" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/lalit98971" target="_blank" rel="noopener noreferrer">
                    <img src="assets/img/footer/x.svg" alt="Twitter" loading="lazy" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/lmtechnologies.in" target="_blank" rel="noopener noreferrer">
                    <img src="assets/img/footer/instagram.svg" alt="Instagram" loading="lazy" />
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="scroll_down">
            <div className="container">
              <p>
                <a href="#services">Scroll to Down</a>
              </p>
            </div>
          </div>
        </div>
        {/*------- hero section end ----------- */}


    


        {/*------- services section start ----------- */}
        <div className="services" id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 m-auto pe-lg-4">
                <div className="main_heading">
                  <h5 className="revealer">OUR PRIMARY EFFORTS</h5>
                  <h1 className="revealer">Developing excellence Apps</h1>
                  <p className="revealer">
                    We are developing premium apps, harnessing foundational technologies and collaborating with expert
                    developer teams.
                  </p>
                </div>
                <div className="btm_btn btn_web">
                  <a href="/service" className="revealer btn see_more">
                    See More
                  </a>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 ps-lg-4">
                <div className="row g-3">
                  {services.map((service, index) => (
                    <div key={index} className="col-lg-4 col-md-4 col-6">
                      <div className="services_crd">
                        <div className="position-relative">
                          <img src={`assets/img/home/service/${service.img}`} alt={service.title} loading="lazy" className="revealer" />
                          <div className="content">
                            <h3 className="revealer">{service.title}</h3>
                            <p className="revealer">{service.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------- services section end ----------- */}

        {/*------- product section start ----------- */}
        <div className="project_success">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-12 offset-lg-1">
                <div className="main_heading">
                  <h1 className="revealer">Our Product</h1>
                  <p className="revealer first">
                    We understand the importance of customer satisfaction and provide the best services. Working with clients
                    to achieve their business goals and nurturing long-term relationships that matter.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="project_slider owl-carousel">
            <div className="slider_main">
              <div className="row g-0 px-lg-4 px-2">
                <div className="col-lg-4 col-md-4">
                  <div className="slider_sub">
                    <a href="https://bloopers.app" target='_blank' rel='noopener noreferrer'>
                      <img src="assets/img/home/product/logo.png" alt="Bloopers" loading="lazy" />
                    </a>
                    <div className="text_sec">
                      <h5>Bloopers: instantly trending short videos</h5>
                      <h3>Bloopers By Lalit Mittal Technologies Pvt Ltd</h3>
                      <p>
                        Discover the world of instant virality with Bloopers! Create and share short videos that capture the
                        essence of the moment. Join the viral sensation – your shortcut to laughter, creativity, and trending
                        content.
                      </p>
                    </div>
                    <a href="https://bloopers.app" target='_blank' rel='noopener noreferrer' className="case_study btn position-relative">
                      View Product
                    </a>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 order-first order-lg-last order-md-last">
                  <div className="img_sec">
                    <img src="assets/img/home/product/product2.svg" alt="Product Image" loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------- product section end ----------- */}





  {/*------- our_approach section start ----------- */}
           <div className="our_approch">
          <div className="container">
            <div className="main_heading">
              <h1 className="revealer">OUR APPROACH</h1>
              <p className="revealer">
                Embark on your product development journey with Tech Solutions Inc. and discover how collaborating with
                industry professionals can elevate your success.
              </p>
            </div>
            <div className="process">
            <div className="top_section">
                <div className="row g-md-0 revealer">
                  {approachSteps.slice(0, 4).map((step, index) => (
                    <div key={index} className="col-lg-3 col-md-3">
                      <div className="row g-3">
                        <div className="col-md-4 col-lg-4 col-4">
                          <div className="img_bg">
                            <img src={`assets/img/home/approach/${step.img}`} alt={step.title} loading="lazy" className="process_img" />
                          </div>
                        </div>
                        <div className="col-md-8 col-lg-8 col-8">
                          <div className="process_text">
                            <h1>{`0${index + 1}`}</h1>
                            <h5>{step.title}</h5>
                            <p>{step.text}</p>
                          </div>
                          <div className="verticle_line" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="btm_section">
                <div className="row g-md-0 revealer">
                  {approachSteps.slice(4).map((step, index) => (
                    <div key={index} className="col-lg-3 col-md-3">
                      <div className="verticle_line" />
                      <div className="row g-3">
                        <div className="col-md-4 col-lg-4 col-4">
                          <div className="img_bg">
                            <img src={`assets/img/home/approach/${step.img}`} alt={step.title} loading="lazy" className="process_img" />
                          </div>
                        </div>
                        <div className="col-md-8 col-lg-8 col-8">
                          <div className="process_text">
                            <h1>{`0${index + 5}`}</h1>
                            <h5>{step.title}</h5>
                            <p>{step.text}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------- our_approach section end ----------- */}


    

        {/*------- technology section start ----------- */}
        <div>
          <div className="platforms">
            <div className="container">
              <div className="main_heading">
                <h5 className="revealer">OUR TECH STACKS</h5>
                <h1 className="revealer">Expertise in Cutting-Edge Technologies</h1>
                <p className="revealer first">
                  Throughout our journey, we have carefully curated a selection of tools that empower us to construct secure,
                  scalable, and top-quality online marketplaces. These tools, combined with our streamlined development
                  process, allow us to deliver custom marketplace website development services that align with our clients'
                  budget and expectations.
                </p>
                <p className="revealer last">
                  These tools are the driving force behind our ability to provide cost-effective custom marketplace website
                  development services while exceeding our clients' expectations.
                </p>
              </div>
              <div className="platforms_sub">
                <div className="row">
                  {techStacks.map((tech, index) => (
                    <div key={index} className="col-lg-3 col-md-3 col-6">
                      <div className="main_sec">
                        <div className="heading">
                          <h1 className="revealer">{tech.category}</h1>
                        </div>
                        <ul className="frontend_type">
                          {tech.items.map((item, idx) => (
                            <li key={idx} className="revealer">
                              <img src={`assets/img/home/all_tec/${item}`} alt={item.replace('.svg', '')} loading="lazy" />
                              {item.replace('.svg', '').replace(/-/g, ' ').replace(/svgrepo com/, '')}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x={48} y={0} fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x={48} y={3} fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x={48} y={5} fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x={48} y={7} fill="#f6f4fe" />
            </g>
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
          </svg>
        </div>
        {/*------- technology section end ----------- */}
      </section>
      {/* ======= home component end =============== */}
    </>
  );
};

export default Home;
